import React, { useEffect } from "react";
import qore from "../../context/qoreContext";
import CommercePageComponent from "../../components/skin-care/Commerce";
import ImageGallery from "react-image-gallery";
import "../../css/image-gallery.css";
import Skeleton from "react-loading-skeleton";
import ClaimItems from "./ClaimItems";
import {
  Box,
  Text,
  Flex,
  Container,
  Image,
  Button,
  SimpleGrid,
  useMediaQuery,
  Spacer,
  Center,
  Stack,
  Modal
} from "@chakra-ui/react";

import dermTested from "../../images/dermTested.png";
import nonComedo from "../../images/nonComedo.png";
import nonAcne from "../../images/nonAcne.png";
import ProductModal from "../product/ProductModal";

const Details = ({ name }) => {
  const [productImages, setProductImages] = React.useState([]);
  const [productShow, setProductShow] = React.useState(null);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [modalShow, setModalShow] = React.useState(false);
  const [popupData, setPopupData] = React.useState({});

  const { data: SkincareData, status: statusProduct } = qore.views.skincareProducts.useListRow({
    order: "asc",
    name: name
  });

  useEffect(() => {
    const productDefault = SkincareData[0];
    setProductShow(productDefault);
    const images = [
      {
        original: SkincareData[0]?.imgProduct,
        thumbnail: SkincareData[0]?.imgProduct
      },
      {
        original: SkincareData[0]?.imgSwatch,
        thumbnail: SkincareData[0]?.imgSwatch
      },
      {
        original: SkincareData[0]?.imgLip,
        thumbnail: SkincareData[0]?.imgLip
      }
    ];
    setProductImages(images);
  }, [SkincareData]);

  useEffect(() => {
    if (productShow) {
      const images = [
        {
          original: SkincareData[0]?.imgProduct,
          thumbnail: SkincareData[0]?.imgProduct
        },
        {
          original: SkincareData[0]?.imgSwatch,
          thumbnail: SkincareData[0]?.imgSwatch
        },
        {
          original: SkincareData[0]?.imgLip,
          thumbnail: SkincareData[0]?.imgLip
        }
      ];
      setProductImages(images);
    }
  }, [productShow]);

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleBuy = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgBottle,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow(true);
  };

  return (
    <>
      <Container
        maxW={{ sm: "container.md", md: "container.lg", lg: "container.lg", xl: "container.xl" }}
        py={10}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ sm: 4, md: 8, lg: 10 }}>
          <Box>
            <Flex position={isMobile ? "auto" : "sticky"} top={isMobile ? "auto" : "40"}>
              {!isMobile && <Spacer />}
              {statusProduct === "success" ? (
                <>
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={isMobile ? true : false}
                    items={productImages}
                    showThumbnails={isMobile ? false : true}
                    showBullets={isMobile ? true : false}
                  />
                  <Image
                    src={SkincareData[0]?.imgBottle}
                    height={{ base: "30%", sm: "25%", md: "30%", lg: "30%", xl: "25%" }}
                    width={{ base: "30%", sm: "25%", md: "30%", lg: "30%", xl: "25%" }}
                    alt={SkincareData[0]?.name}
                    transform={SkincareData[0]?.isSachet ? "rotate(90deg) scale(1.5)" : ""}
                    mt={SkincareData[0]?.isSachet ? 6 : 0}
                  />
                </>
              ) : (
                <>
                  <Skeleton
                    height={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                    width={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                  />
                  <Skeleton
                    ml={1}
                    height={{ base: "25%", md: "10%", lg: "15%", xl: "25%" }}
                    width={{ base: "25%", md: "10%", lg: "15%", xl: "25%" }}
                  />
                </>
              )}
              {!isMobile && <Spacer />}
            </Flex>
          </Box>
          <Box>
            <Text
              textAlign="left"
              fontWeight="bold"
              fontSize="26px"
              mb={isMobile ? 1 : 8}
              mt={isMobile ? 4 : 0}>
              {SkincareData[0]?.name}
            </Text>
            <Text textAlign="left" fontSize="18px" mb={4}>
              No. BPOM: {SkincareData[0]?.bpom}
            </Text>
            <Stack direction={["column", "row"]} px={2} spacing="20px">
              <Button
                _focus={{ boxShadow: "none" }}
                _hover={{
                  bg: "black"
                }}
                onClick={() => handleBuy(SkincareData[0])}
                colorScheme="blackAlpha"
                color="white"
                rounded="3xl"
                w="100%"
                h="44px"
                bg="#0f0f0f">
                Beli
              </Button>
            </Stack>
            <SimpleGrid columns={{ base: 3 }} spacing={{ md: 4, lg: 10 }} pt={10}>
              <Box textAlign="center">
                <Center>
                  <Image
                    src={SkincareData[0]?.claimImage1 ? SkincareData[0]?.claimImage1 : dermTested}
                    width="30%"
                    mb={2}
                  />
                </Center>
                <Text textAlign="center" fontWeight="bold" fontSize="14px" width="100%">
                  {SkincareData[0]?.claimText1
                    ? SkincareData[0]?.claimText1
                    : "Dermatologically Tested"}
                </Text>
              </Box>
              <Box textAlign="center">
                <Center>
                  <Image
                    src={SkincareData[0]?.claimImage2 ? SkincareData[0]?.claimImage2 : nonComedo}
                    width="30%"
                    mb={2}
                  />
                </Center>
                <Text textAlign="center" fontWeight="bold" fontSize="14px" width="100%">
                  {SkincareData[0]?.claimText2 ? SkincareData[0]?.claimText2 : "Non Comedogenic"}
                </Text>
              </Box>
              <Box>
                <Center>
                  <Image
                    src={SkincareData[0]?.claimImage3 ? SkincareData[0]?.claimImage3 : nonAcne}
                    width="30%"
                    mb={2}
                  />
                </Center>
                <Text textAlign="center" fontWeight="bold" fontSize="14px" width="100%">
                  {SkincareData[0]?.claimText3 ? SkincareData[0]?.claimText3 : "Non Acnegenic"}
                </Text>
              </Box>
            </SimpleGrid>
            <Text textAlign="left" fontSize="16px" mt={8}>
              {SkincareData[0]?.productDescription}
            </Text>
            <ClaimItems
              tipsPenggunaan={SkincareData[0]?.tipsPenggunaan}
              productInformation={SkincareData[0]?.productInformation}
              ingredients={SkincareData[0]?.ingredients}
              faq={SkincareData[0]?.faq}
            />
          </Box>
        </SimpleGrid>
      </Container>
      <CommercePageComponent PopUpData={SkincareData[0]} />
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default Details;
