import React from "react";
import { Container, useMediaQuery, Text, SimpleGrid, Box, Image, Center } from "@chakra-ui/react";
import SkinResultBanner from "../../images/SkinResultBanner.png";
import "../../css/banner.css";

import skincareResult1 from "../../images/skincareResult1.png";
import skincareResult2 from "../../images/skincareResult2.png";
import skincareResult3 from "../../images/skincareResult3.png";

const SkinResult = () => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <Box
        bgImage={SkinResultBanner}
        bgSize="cover"
        bgRepeat="no-repeat"
        py="3em"
        px={isMobile ? 4 : 0}>
        <Text textAlign="center" fontWeight="bold" fontSize={isMobile ? "28px" : "36px"} mb={3}>
          Skin Result
        </Text>
        <Text textAlign="center" fontSize={isMobile ? "20px" : "24px"}>
          <strong>100%</strong> Kulit tidak terasa kusam, tampak halus, lembut, dan sehat.
        </Text>
        <Text textAlign="center" fontSize={isMobile ? "20px" : "24px"} pb="1em">
          <strong>93%</strong> Kulit tidak tertarik. <strong>86%</strong> Tampak lebih cerah glowing
          dan kenyal.
        </Text>
        <Text textAlign="center" fontSize={isMobile ? "16px" : "18px"} pb="3em" textColor="#66686C">
          *berdasarkan uji efikasi terhadap 28 wanita Indonesia setelah 2 minggu pemakaian
        </Text>
        <Container maxW={{ lg: "container.md", xl: "container.xl" }}>
          <SimpleGrid columns={isMobile ? 1 : 3} spacing={isMobile ? 4 : 8}>
            <Box>
              <Center>
                <Image src={skincareResult1} w="85%" objectFit="contain" loading="lazy" pb="1em" />
              </Center>
            </Box>
            <Box>
              <Center>
                <Image src={skincareResult2} w="85%" objectFit="contain" loading="lazy" pb="1em" />
              </Center>
            </Box>
            <Box>
              <Center>
                <Image src={skincareResult3} w="85%" objectFit="contain" loading="lazy" pb="1em" />
              </Center>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
};

export default SkinResult;
