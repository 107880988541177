import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SkincareDetailPageComponent from "../../components/skin-care/Details";
import SkinResultPageComponent from "../../components/skin-care/SkinResult";
import BundlePageComponent from "../../components/skin-care/Bundle";
import BestReview from "../../components/skin-care/BestReview";
import RecommendationPageComponent from "../../components/skin-care/Recommendations";
import OMGMakeup from "../../components/skin-care/OMGMakeup";

const detail = props => {
  const name = props.params["*"];
  return (
    <Layout>
      <Seo title={`Oh My Glow ${name}`} />
      <SkincareDetailPageComponent name={name} />
      <SkinResultPageComponent name={name} />
      <BestReview name={name} />
      <BundlePageComponent name={name} />
      <RecommendationPageComponent name={name} />
      <OMGMakeup />
    </Layout>
  );
};

export default detail;
