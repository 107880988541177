import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";

import DOMPurify from "dompurify";
import styled from "styled-components";

const ArticleContainer = styled.div`
  font-family: "Avenir";
  font-size: "14px";

  p {
    margin-bottom: 14px;
  }
`;

const ClaimItems = ({ tipsPenggunaan, productInformation, ingredients, faq }) => {
  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  return (
    <Box mt={{ base: 8, lg: 10 }}>
      <Accordion mt={8} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>Tips Penggunaan</b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {tipsPenggunaan ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(tipsPenggunaan)} />
            ) : (
              "Belum ada Tips Penggunaan"
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>Product Information</b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {productInformation ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(productInformation)} />
            ) : (
              "Belum ada data Informasi Produk"
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>Ingredients</b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {ingredients ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(ingredients)} />
            ) : (
              "Belum ada data Komposisi"
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>FAQ</b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {faq ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(faq)} />
            ) : (
              "Belum ada data FAQ"
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ClaimItems;
