import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  useMediaQuery,
  SimpleGrid,
  Center,
  Container
} from "@chakra-ui/react";

import PropTypes from "prop-types";

import blibli from "../../images/blibli.png";
import lazada from "../../images/lazada.png";
import shopee from "../../images/shopee.png";
import tiktok from "../../images/tiktok.png";
import toko from "../../images/toko.png";
import tokopedia from "../../images/tokopedia.png";
import whatsapp from "../../images/whatsapp.png";
import CommerceBannerDesktop from "../../images/CommerceBannerDesktop.jpg";

const Commerce = ({ PopUpData }) => {
  const [isMobile] = useMediaQuery("(max-width: 820px)");

  const BoxStyle = {
    boxShadow: "5px 5px 1px",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: "2px"
  };

  return (
    <>
      <Box bgImage={CommerceBannerDesktop} bgSize={isMobile ? "500%" : "100%"} bgRepeat="no-repeat">
        <Container maxW={{ lg: "container.md", xl: "container.xl" }} p={10}>
          <Text mb={6} fontSize="24px" fontWeight="bold">
            Dapatkan Produk Kami di sini
          </Text>
          <Flex direction={isMobile ? "column" : "row"}>
            <Box w={isMobile ? "100%" : "50%"}>
              <Text mb={3} fontSize="18px" fontWeight="bold">
                OMG Official Store
              </Text>
              <SimpleGrid columns={isMobile ? 2 : 3} spacing={isMobile ? 4 : 6} mb={6}>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.whatsapp} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={whatsapp} />
                  </a>
                </Center>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.tiktok} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={tiktok} />
                  </a>
                </Center>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.shopee} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={shopee} />
                  </a>
                </Center>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.tokopedia} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={tokopedia} />
                  </a>
                </Center>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.lazada} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={lazada} />
                  </a>
                </Center>
                <Center bg="white" height="50px" style={BoxStyle}>
                  <a href={PopUpData?.blibli} target="_blank" rel="noopener noreferrer">
                    <Image width="150%" height="150%" layout="constrained" src={blibli} />
                  </a>
                </Center>
              </SimpleGrid>
            </Box>
            <Box w={isMobile ? "100%" : "50%"} alignItems="center" pl={isMobile ? 0 : "75px"}>
              <Text mb={3} fontSize="18px" fontWeight="bold">
                Toko Kosmetik Terdekat
              </Text>
              <Center bg="white" width={isMobile ? "50%" : "35%"} height="50px" style={BoxStyle}>
                <a href={PopUpData?.toko}>
                  <Image width="150%" height="150%" layout="constrained" src={toko} />
                </a>
              </Center>
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

Commerce.propTypes = {
  PopUpData: PropTypes.instanceOf(Object)
};

Commerce.defaultProps = {
  PopUpData: {}
};

export default Commerce;
