import React from "react";
import qore from "../../context/qoreContext";
import { StaticImage } from "gatsby-plugin-image";
import Skeleton from "react-loading-skeleton";
import { Link } from "gatsby";
import ProductModal from "../product/ProductModal";
import styled from "styled-components";
import "../../css/banner.css";
import {
  Container,
  Text,
  VStack,
  SimpleGrid,
  Box,
  Button,
  useMediaQuery,
  Modal,
  Image
} from "@chakra-ui/react";

const CardImage = styled(Box)`
  background: ${props => props.ringcolor};
  border-radius: 50%;
  margin: 0 auto;
`;

const Recommendations = ({ name }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const skeletonCount = [1, 2, 3, 4];
  const [modalShow, setModalShow] = React.useState(false);
  const [popupData, setPopupData] = React.useState({});

  const { data: listProduct, status: statusProduct } = qore.views.skincareProducts.useListRow({
    order: "asc"
  });

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleClick = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgBottle,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow(true);
  };

  return (
    <>
      <Container maxW={{ lg: "container.md", xl: "container.xl" }} py={10}>
        <Text textAlign="center" fontWeight="bold" fontSize={isMobile ? "28px" : "36px"} mb={6}>
          Better to be with
        </Text>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={{ base: 1, md: 1 }}>
          {statusProduct === "success" && listProduct.length
            ? listProduct
                .filter(object => object.name !== name)
                .map(product => {
                  let productLink = `/skincare/detail/${product.name}`;
                  return (
                    <VStack
                      key={product.id}
                      spacing={16}
                      mb={8}
                      transform="scale(0.95)"
                      transition="transform 500ms"
                      _hover={{ transform: "scale(1)", transition: "transform 500ms" }}>
                      <CardImage
                        h={{ base: "120px", md: "160px", xl: "200px" }}
                        w={{ base: "120px", md: "160px", xl: "200px" }}
                        ringcolor={product.hexColor}>
                        <Link to={productLink}>
                          <Image
                            src={product?.imgBottle}
                            alt={product?.name}
                            w={{ base: "120px", md: "150px", xl: "180px" }}
                            h={{ base: "160px", md: "200px", xl: "240px" }}
                            objectFit="contain"
                            loading="lazy"
                            m="0 auto"
                          />
                          {product?.bestSeller && (
                            <Box
                              position="absolute"
                              right={{ base: "36px", md: "20px", xl: "80px" }}
                              top="4px">
                              <StaticImage
                                src="../../images/best-seller.png"
                                alt="best-seller"
                                // loading="lazy"
                                height="40px !important"
                              />
                            </Box>
                          )}
                        </Link>
                      </CardImage>
                      <Box textAlign="left">
                        <Link to={productLink}>
                          <Text fontSize={{ base: "16px", md: "18px" }} minH="30px" width="140px">
                            Peach Glowing
                          </Text>
                          <Text
                            fontSize={{ base: "16px", md: "18px" }}
                            fontWeight="bold"
                            mb={1}
                            width="140px">
                            {product?.name === "Glowing Toner"
                              ? product?.name.replace(/Glowing/g, "")
                              : product?.name.replace(/Peach Glowing/g, "")}
                          </Text>
                          <Text fontSize="lg" mb={5}>
                            {`Rp ${product.price.toLocaleString("id-ID", {
                              maximumFractionDigits: 0
                            })}`}
                          </Text>
                        </Link>
                        <Box textAlign="center">
                          <Button
                            size="sm"
                            width={"88px"}
                            rounded="xl"
                            bg="#262626"
                            colorScheme="blackAlpha"
                            color="white"
                            onClick={() => handleClick(product)}
                            _focus={{ boxShadow: "none" }}
                            _hover={{
                              bg: "black"
                            }}>
                            Beli
                          </Button>
                        </Box>
                      </Box>
                    </VStack>
                  );
                })
            : skeletonCount.map(skeleton => (
                <Box
                  key={skeleton}
                  h={{ base: "200px", md: "180px", xl: "300px" }}
                  w={{ base: "150px", md: "135px", xl: "225px" }}
                  margin="0 auto">
                  <Skeleton height="100%" width="100%" />
                </Box>
              ))}
        </SimpleGrid>
      </Container>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default Recommendations;
